// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-categories-index-js": () => import("./../../../src/pages/categories/index.js" /* webpackChunkName: "component---src-pages-categories-index-js" */),
  "component---src-pages-categories-strapi-category-slug-js": () => import("./../../../src/pages/categories/{StrapiCategory.slug}.js" /* webpackChunkName: "component---src-pages-categories-strapi-category-slug-js" */),
  "component---src-pages-commissions-index-js": () => import("./../../../src/pages/commissions/index.js" /* webpackChunkName: "component---src-pages-commissions-index-js" */),
  "component---src-pages-designer-resources-index-js": () => import("./../../../src/pages/designer-resources/index.js" /* webpackChunkName: "component---src-pages-designer-resources-index-js" */),
  "component---src-pages-files-index-js": () => import("./../../../src/pages/files/index.js" /* webpackChunkName: "component---src-pages-files-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-article-strapi-news-post-slug-js": () => import("./../../../src/pages/news/article-{StrapiNewsPost.slug}.js" /* webpackChunkName: "component---src-pages-news-article-strapi-news-post-slug-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-strapi-board-slug-js": () => import("./../../../src/pages/projects/{StrapiBoard.slug}.js" /* webpackChunkName: "component---src-pages-projects-strapi-board-slug-js" */),
  "component---src-pages-status-index-js": () => import("./../../../src/pages/status/index.js" /* webpackChunkName: "component---src-pages-status-index-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

